WebFont.load({
    google:{
        families: ['El+Messiri:400;500;600;700&display=swap']
    },
    custom: {
        families: ['FontAwesome'],
        urls: [
            'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.1/css/all.min.css',
        ]
    }
});

(function ($) {
    //global params to check if rtl is enabled or not for slick carousel
    window.hasRtl = $("body").hasClass("rtl");

    function savoy_dubai_refresh_size_queries() {
        var classes = [];
        var scrollbarwidth = savoy_dubai_getScrollbarWidth();
        window_width = $(window).width() + scrollbarwidth;
        window_height = $(window).height();
        is_phone = (window_width < 768);
        is_mobile = (window_width < 992);
        is_tablet_portrait = (window_width >= 768 && window_width < 992);
        is_tablet_landscape = (window_width >= 992 && window_width < 1200 && window_height <= 768);
        is_tablet = is_tablet_portrait || is_tablet_landscape;
        is_desktop = (window_width >= 992);
        is_desktop_large = (window_width >= 1200);

        if (is_phone) {
            classes.push('mq_phone');
        }
        if (is_mobile) {
            classes.push('mq_mobile');
        }
        if (is_tablet_portrait) {
            classes.push('mq_tablet_portrait');
        }
        if (is_tablet_landscape) {
            classes.push('mq_tablet_landscape');
        }
        if (is_tablet) {
            classes.push('mq_tablet');
        }
        if (is_desktop) {
            classes.push('mq_desktop');
        }
        if (is_desktop_large) {
            classes.push('mq_desktop_large');
        }


        $('html').removeClass('mq_phone');
        $('html').removeClass('mq_mobile');
        $('html').removeClass('mq_tablet_portrait');
        $('html').removeClass('mq_tablet_landscape');
        $('html').removeClass('mq_tablet');
        $('html').removeClass('mq_desktop');

        $('html').addClass(classes.join(' '));

    }

    function savoy_dubai_getScrollbarWidth() {
        var outer = document.createElement("div");
        outer.style.visibility = "hidden";
        outer.style.width = "100px";
        outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps
        document.body.appendChild(outer);
        var widthNoScroll = outer.offsetWidth;
        // force scrollbars
        outer.style.overflow = "scroll";
        // add innerdiv
        var inner = document.createElement("div");
        inner.style.width = "100%";
        outer.appendChild(inner);
        var widthWithScroll = inner.offsetWidth;
        // remove divs
        outer.parentNode.removeChild(outer);
        return widthNoScroll - widthWithScroll;
    }

    function savoy_dubai_seo_position() {
        $('#seobar_wrapper').appendTo($('#seobar_content'));
    }

    function savoy_dubai_header_position() {
        var header = $('#header_wrapper'),
            custom = 'custom',
            fixed = 'fixed',
            slideshow = $('#map_wrapper').length > 0 ? $('#map_wrapper') : $('.slideshow-container'),
            breakpoint = slideshow.height() - header.outerHeight(true) - 90,
            scroll = $(window).scrollTop();
        if (slideshow.length > 0) {
            if (scroll > breakpoint) {
                header.addClass(fixed);
                if(!is_mobile){
                    $('.bookingform-wrap').insertAfter('.header-container');
                }
            } else {
                header.removeClass(fixed);
                if(!is_mobile){
                    $('.bookingform-wrap').insertAfter('.slideshow');
                }
            }
        } else {
            header.addClass(fixed);
            if(!is_mobile){
                $('.bookingform-wrap').insertAfter('.header-container');
            }
        }
    }

    function savoy_dubai_languages_select(){
        $('.current-language').on('click', function(){
            if(!$('.other-languages').hasClass('show')){
                $('.other-languages').addClass('show');
                $('.other-languages').fadeIn();
            } else {
                $('.other-languages').removeClass('show');
                $('.other-languages').fadeOut();
            }
        });

        $(document).mouseup(function(e){
            if (!$('.current-language').is(e.target) && $('.current-language').has(e.target).length === 0) {
                $('.other-languages').removeClass('show');
                $('.other-languages').fadeOut();
            }
        });
    }

    function savoy_dubai_hotels_select(){
        $('.current-hotel').on('click', function(){
            if(!$('.other-hotels').hasClass('show')){
                $('.other-hotels').addClass('show');
                $('.other-hotels').fadeIn();
            } else {
                $('.other-hotels').removeClass('show');
                $('.other-hotels').fadeOut();
            }
        });

        $(document).mouseup(function(e){
            if (!$('.current-hotel').is(e.target) && $('.current-hotel').has(e.target).length === 0) {
                $('.other-hotels').removeClass('show');
                $('.other-hotels').fadeOut();
            }
        });
    }

    function savoy_dubai_sidebar_menu(){
        var link = $('.hamburger-menu'),
            wrap = $('.menu-sidebar-wrap'),
            close = wrap.find('.close');
        if (link.length > 0 && wrap.length > 0) {
            link.click(function () {
                wrap.addClass('open');
                setTimeout(function () {
                    wrap.addClass('visible');
                }, 300);
                $('html').addClass('no-scroll');
            });

            close.click(function () {
                wrap.removeClass('visible');
                setTimeout(function () {
                    wrap.removeClass('open');
                }, 300);
                $('html').removeClass('no-scroll');
            });
        }
    }
    function savoy_dubai_sidebarmenu_submenu() {
        $('<span class="more_li"><span class="fas fa-angle-down"></span></span>').appendTo($('#primary_menu .menu-item-has-children'));

        var button = $("#primary_menu .menu .more_li"),
            parent = $("li.menu-item-has-children"),
            submenu = $("ul.sub-menu"),
            mClass = "open",
            wrapper = $(".menu_sidebar");

        button.click(function () {
            if (!$(this).closest(parent).hasClass('open')) {
                parent.removeClass(mClass);
                parent.find(submenu).stop().fadeOut();

                $(this).closest(parent).find(submenu).stop().fadeIn(400,
                    function () {
                        $(this).closest(parent).addClass(mClass);
                    }
                );
            } else {
                $(this).closest(parent).find(submenu).stop().fadeOut(function () {
                    400,
                        $(this).closest(parent).removeClass(mClass);
                });
            }

        });
        parent.find('> a').click(function () {
            var link = $(this);
            if (link.attr('href') == 'javascript:;') {

                if (!link.closest(parent).hasClass('open')) {
                    parent.removeClass(mClass);
                    parent.find(submenu).stop().fadeOut();

                    $(this).closest(parent).find(submenu).stop().fadeIn(400,
                        function () {
                            $(this).closest(parent).addClass(mClass);
                        }
                    );
                } else {
                    link.closest(parent).find(submenu).stop().fadeOut(function () {
                        400,
                            $(this).closest(parent).removeClass(mClass);
                    });
                }

            }
        });
    }

    function savoy_dubai_slideshow_height(){
        var WH = $(window).height();
        var sl_height;
        if($('html').hasClass('group-hp') || $('html').hasClass('hotel-hp')){
            if(is_mobile){
                sl_height = WH - 60;
            } else{
                sl_height = WH;
            }
            $('.slideshow').css({
                'height' : sl_height
            });
        } else {
            if(WH >= 648 && WH <= 900){
                sl_height = WH - 120;
            } else{
                sl_height = WH - 225;
            }

            $('.slideshow').css({
                'height' : sl_height
            });
        }
    }

    function savoy_dubai_slideshow(){
        $('.slideshow').not('.slick-initialized').slick({
            rows:0,
            speed: slideshow_speed,
            autoplaySpeed: slideshow_autoplay_speed,
            arrows: false,
            dots: false,
            autoplay: true,
            fade: true,
            pauseOnHover: false,
            rtl: hasRtl
        });


        $('.slideshow').on('beforeChange', function (event, slick, currentSlide, nextSlide) {

            var slideIndexToPreload = (nextSlide > currentSlide ? nextSlide : currentSlide - 1);
            var slideToPreload = $(slick.$slides.get(slideIndexToPreload));
            var slideToPreloadPicture = slideToPreload.find('picture');

            $(slideToPreloadPicture).each(function () {
                var slideToPreloadSourceSets = $(this).find('source');
                var slideToPreloadImg = $(this).find('img');
                if (slideToPreloadImg.data('load') === 'no-loaded') {

                    for (var i = 0; i < slideToPreloadSourceSets.length; i++) {
                        if (slideToPreloadSourceSets[i].getAttribute('data-lazy-srcset') != null) {
                            slideToPreloadSourceSets[i].setAttribute('srcset', slideToPreloadSourceSets[i].getAttribute('data-lazy-srcset'));
                            slideToPreloadSourceSets[i].removeAttribute('data-lazy-srcset');
                        }
                    }

                    for (var i = 0; i < slideToPreloadImg.length; i++) {
                        if (slideToPreloadImg[i].getAttribute('data-lazy-src')) {
                            slideToPreloadImg[i].setAttribute('src', slideToPreloadImg[i].getAttribute('data-lazy-src'));
                            slideToPreloadImg[i].removeAttribute('data-lazy-src');
                        }
                    }

                    slideToPreloadImg.attr('data-load', 'loaded');
                } else {
                    return;
                }

            });
        });


        if(is_mobile){
            $('.offer_for_login').insertAfter('.slideshow');
        }
    }

    function savoydubai_collapsibleContent(){
        var button = $('.the-content-section .discover-more');
        if(button.length > 0){
            button.on('click',function () {
                if (!$(this).hasClass('open')) {
                    $(this).addClass('open');
                    $('.the-extra-content-wrapper').css({
                        height: $('.the-extra-content-wrapper .the-extra-content').outerHeight(true)
                    });
                    $(this).text($(this).data('closetext'));
                } else {
                    $(this).removeClass('open');
                    $('.the-extra-content-wrapper').css({
                        height: 0
                    });
                    $(this).text($(this).data('opentext'));
                }
            });
        }
    }

    function savoy_dubai_default_fancybox(){
        $('[data-fancybox]:not(.slick-cloned)').fancybox({
            buttons: [
                "close"
            ],
        });
    }

    function savoy_dubai_minigallery(){
        $('.minigallery-carousel').on('init', function(event, slick){
            lazyLoadInstance.update();
        });
        $('.minigallery-carousel').not('.slick-initialized').slick({
            rows: 0,
            slidesToShow: 5,
            centerMode: true,
            arrows: false,
            dots: true,
            variableWidth: true,
            rtl: hasRtl,
            prevArrow: '<a class="slick-arrow prev" href="javascript:;"><span></span></a>',
            nextArrow: '<a class="slick-arrow next" href="javascript:;"><span></span></a>',
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 1,
                        prevArrow: '<a class="slick-arrow rounded prev" href="javascript:;"><span></span></a>',
                        nextArrow: '<a class="slick-arrow rounded next" href="javascript:;"><span></span></a>',
                    }
                },
            ]
        });
    }

    function savoy_dubai_reviews(){
        $('.reviews-section .reviews-carousel .reviews').on('init', function(event, slick){
            lazyLoadInstance.update();
        });
        $('.reviews-section .reviews-carousel .reviews').not('.slick-initialized').slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            dots: false,
            rtl: hasRtl,
            prevArrow: '<a class="slick-arrow rounded prev" href="javascript:;"><span></span></a>',
            nextArrow: '<a class="slick-arrow rounded next" href="javascript:;"><span></span></a>',
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                    }
                },
            ]
        });
    }

    function savoy_dubai_web_sdk_offers() {
        $fbsdk.Config.baseHost = 'websdk.fastbooking-services.com';
        $('div[data-websdk]').each(function () {
            var container = $(this),
                model_name = container.data('websdk'),
                id = container.attr('id'),
                custom_template = container.data('websdk-template'),
                template_id, config, model, template, html;
            template_id = custom_template ? custom_template : '#' + id + '_template';
            template = $(template_id).text();
            if (typeof websdk_config !== 'undefined' && typeof websdk_config[id] !== 'undefined') {
                config = websdk_config[id];
                $fbsdk.baseHost = config.baseHost;
                model = new $fbsdk[model_name](config.params);
                model.setAuthToken(config._authCode);
                model.load(function (error) {
                    var data, i;
                    if (!error) {
                        //YEAH, this happens when we have data and we're ready to use it!
                        data = JSON.parse(JSON.stringify(this.getRawData()));
                        if (model_name == 'Offers' && data.rates.length == 0) {
                            container.addClass('no_offers');
                            if($('html').hasClass('single_room_page') && container.hasClass('hidden-if-empty')){
                                container.closest('.section').hide();
                            }
                            container.closest('.special_offers_row').find('.hotel .price').remove();
                        }
                        container.closest('.loader').addClass(' processed noloader');

                        if (typeof data.rates !== 'undefined') {

                            if (typeof config.excluded_offers !== 'undefined' && config.excluded_offers !== '') {
                                var temp_rates = [];
                                for (var i = 0; i < data.rates.length; i++) {
                                    if (typeof data.rates[i] !== 'undefined') {
                                        if ($.inArray(data.rates[i].rate.name, config.excluded_offers) == -1) {
                                            // if is allowed
                                            temp_rates.push(data.rates[i]);
                                        }
                                    }
                                }
                                // OVERRRIDE THE OBJ
                                data.rates = temp_rates;
                            }

                            // limit numbers
                            if (typeof config.offers_number !== 'undefined' && config.offers_number !== '') {
                                if(data.rates.length > parseInt(config.offers_number, 10)) {
                                    data.rates.length = parseInt(config.offers_number, 10);
                                }
                            }
                            for (var i = 0; i < data.rates.length; i++) {
                                if (i % 2 == 0) {
                                    data.rates[i].rate.classname = 'even';
                                } else {
                                    data.rates[i].rate.classname = 'odd';
                                }
                                data.rates[i].rate.shortdescription = data.rates[i].rate.plain_description.substr(0, 120) + '...';
                            }
                        }

                        html = Mustache.render(template, data);
                        container.html(html);

                        //Update lazyload images
                        lazyLoadInstance.update();

                        //da qui in poi ho  HTML delle offerte nella pagina
                        if($('.preview-offers').length > 0){
                            savoy_dubai_preview_offers_carousel();
                        }
                        if($('.offers_list ').length > 0){
                            savoy_dubai_equal_height();
                        }

                    } else {
                        // in caso di errore
                        container.fadeOut();
                    }
                });
            }
        });
    }

    function savoy_dubai_equal_height(){
        var maxHeight = 0;

        $(".offer_wrap .offer .content").each(function(){
            if ($(this).height() > maxHeight) { maxHeight = $(this).height(); }
        });

        $(".offer_wrap .offer .content").height(maxHeight);
    }

    function savoy_dubai_align_text() {
        var wrapper = $('.border');
        if(!is_mobile){
            wrapper.each(function(){
                if($(this).hasClass('top')){
                    var title_height = $(this).find('.the-title-container').outerHeight() /2;
                    $(this).find('.the-title-container').css({
                        'top' : title_height
                    })
                }
                if($(this).hasClass('bottom')){
                    var discover_height = $(this).find('.view-all-btn').outerHeight() /2;
                    $('.view-all-btn').css({
                        'bottom' : discover_height
                    })
                }
            });
        }
    }

    function savoy_dubai_rooms_services(){
        var rooms_services_wrapper = $('.rooms-services');
        if(rooms_services_wrapper.length > 0){
            var button = rooms_services_wrapper.find('.discover-more');
            button.on('click', function(){
               if(!button.hasClass('open')){
                   button.addClass('open');
                   $('.rooms-extra-services-container').css({
                       height: $('.rooms-extra-services').outerHeight(true)
                   });
                   button.html(button.data('closetext'));
               } else {
                   button.removeClass('open');
                   $('.rooms-extra-services-container').css({
                       'height': '0'
                   });
                   button.html(button.data('opentext'));
               }
            });
        }
    }

    function savoy_dubai_preview_rooms_carousel(){
        var preview_rooms = $('.preview-rooms-children');
        var children_rooms = preview_rooms.children();
        var show_carousel = false;
        if(is_mobile && children_rooms.length > 0){
            show_carousel = true;
        } else{
            if(children_rooms.length > 2){
                show_carousel = true;
            }
        }

        if(show_carousel){
            preview_rooms.not('.slick-initialized').slick({
                rows: 0,
                slidesToShow: 2,
                variableWidth: false,
                rtl: hasRtl,
                infinite: false,
                prevArrow: '<a class="slick-arrow prev" href="javascript:;"><span></span></a>',
                nextArrow: '<a class="slick-arrow next" href="javascript:;"><span></span></a>',
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 1,
                            prevArrow: '<a class="slick-arrow rounded prev" href="javascript:;"><span></span></a>',
                            nextArrow: '<a class="slick-arrow rounded next" href="javascript:;"><span></span></a>',
                        }
                    },
                ]
            });
        }
    }

    function savoy_dubai_preview_offers_carousel(){
        var manual_offers_hub = $('html').hasClass('tpl-hotel-manual-offers');
        if(!manual_offers_hub && !is_mobile){
            if(!$('.offers_list .offer_wrap').hasClass('only-social-connect')){
                $('.offers_list .offer_wrap').hide().slice(0,3).show();
            }
        }
        if(is_mobile){
            var wrap_offers = $('.preview-offers');
            if (wrap_offers.length > 0) {
                wrap_offers.on('init', function(event, slick){
                    lazyLoadInstance.update();
                });
                wrap_offers.not('.slick-initialized').slick({
                    rows: 0,
                    slide: '.offer_wrap',
                    rtl: hasRtl,
                    slidesToShow: 1,
                    prevArrow: '<a class="slick-arrow rounded prev" href="javascript:;"><span></span></a>',
                    nextArrow: '<a class="slick-arrow rounded next" href="javascript:;"><span></span></a>',
                });
            }
        }
    }

    function savoy_dubai_initializeMapGroup(div){
        if (typeof(div) === "undefined") div = "map_canvas";
        if (typeof(directionsService) === "undefined") directionsService = new google.maps.DirectionsService();
        if (typeof(bounds) === "undefined" && map_config.set_bounds) bounds = new google.maps.LatLngBounds();
        directionsDisplay = new google.maps.DirectionsRenderer();

        var marker, i;

        var infoWindow = new google.maps.InfoWindow({
            'maxWidth': 500,
            'maxHeight': 400,
        });


        center = [map_config.markers[0].latitude, map_config.markers[0].longitude];

        map = new google.maps.Map(document.getElementById(div), {
            // scroll wheel
            scrollwheel: false,
            //zoom
            zoom: map_config.map_zoom,
            zoomControl: map_config.map_zoom_control,
            zoomControlOptions: {
                position: google.maps.ControlPosition[map_config.zoom_control_position]
            },
            //position
            center: new google.maps.LatLng(center[0], center[1]),
            //map type
            mapTypeId: google.maps.MapTypeId[map_config.map_type],
            mapTypeControl: map_config.map_type_control,
            mapTypeControlOptions: {
                position: google.maps.ControlPosition.TOP_RIGHT,
                style: google.maps.MapTypeControlStyle.DEFAULT
            },
            // default pan control
            panControl: false,
            panControlOptions: {
                position: google.maps.ControlPosition.RIGHT_TOP
            },
            // scale control - image with the scale index (m,km,etc)
            scaleControl: false,
            scaleControlOptions: {
                position: google.maps.ControlPosition.TOP_RIGHT
            },
            // 	streetview controls
            streetViewControl: map_config.map_streetview_control,
            streetViewControlOptions: {
                position: google.maps.ControlPosition.TOP_RIGHT
            }

        });

        var styles = [];
        map.setOptions({styles: styles});

        if($('html').hasClass('group-hp')){
            var start_marker = 1;
        } else{
            var start_marker = 0;
        }

        for (i = start_marker; i < map_config.markers.length; i++) {
                marker = new google.maps.Marker({
                    position: new google.maps.LatLng(map_config.markers[i].latitude, map_config.markers[i].longitude),
                    map: map,
                    icon: map_config.markers[i].icon_hotel,
                    zIndex:999999999,
                    visible: true
                });

                markers.push(marker);

            if($('html').hasClass('group-hp')){
                bounds.extend(marker.position);
            }

                google.maps.event.addListener(marker, 'click', (function (marker, i) {
                    return function () {
                        infoWindow.setContent(map_config.markers[i].html);
                        infoWindow.open(map, marker);
                    };
                })(marker, i));

            if($('html').hasClass('group-hp')){
                if (map_config.set_bounds) {
                    map.fitBounds(bounds);
                }
            }

        }
    }

    function savoydubai_calcRoute(div) {
        var start = document.getElementById('itineraryFrom').value;
        var end = '' + map_config.hotel_info.latitude + ',' + map_config.hotel_info.longitude;

        // remove previous message if present
        if ($('.itineraryPanelWrapper').length > 0) {
            $('.itineraryPanelWrapper').remove();
        }
        // delete previous results
        if (typeof directionsDisplay === 'undefined') {
            directionsDisplay = new google.maps.DirectionsRenderer();
        }
        //reset close button display
        $(".close_map.bottom").css("display", "inherit");

        $('#map_directions').fadeIn();

        directionsDisplay.setPanel(null);
        directionsDisplay.setMap(map);
        directionsDisplay.setPanel(document.getElementById('map_directions_content'));


        if (typeof start === 'undefined' || start == '') {
            start = $('input[name=itineraryFrom]').val();
        }

        if (start == '') {
            $('<div class="title">' + empty_message_html + '</div>').appendTo(jQuery('#map_directions_content'));
            directionsDisplay.setPanel(null);
            directionsDisplay.setMap(null);
            map.setZoom(map_config.map_zoom);
            $(".close_map.bottom").css("display", "none");

        } else {

            var request = {
                origin: start,
                destination: end,
                travelMode: google.maps.TravelMode[travel_mode]
            };

            directionsService.route(request, function (response, status) {
                if (status == google.maps.DirectionsStatus.OK) {
                    directionsDisplay.setDirections(response);
                    $('<div class="title ok_messagge">' + ok_message_html + '</div>').appendTo($('#map_directions_content'));
                }
                else if (status == google.maps.DirectionsStatus.NOT_FOUND) {
                    $('<div class="title">' + not_found_message_html + '</div>').appendTo($('#map_directions_content'));
                    directionsDisplay.setPanel(null);
                    directionsDisplay.setMap(null);
                    map.setZoom(map_config.map_zoom);
                    map.setCenter(google.maps.LatLng(map_config.hotel_info.latitude, map_config.hotel_info.longitude));
                    $(".close_map.bottom").css("display", "none");
                }
                else {
                    $('<div class="title">' + err_message_html + '</div>').appendTo($('#map_directions_content'));
                    directionsDisplay.setPanel(null);
                    directionsDisplay.setMap(null);
                    map.setZoom(map_config.map_zoom);
                    map.setCenter(google.maps.LatLng(map_config.hotel_info.latitude, map_config.hotel_info.longitude));
                    $(".close_map.bottom").css("display", "none");
                }
            });
        }

        //this part handle the close_map button
        $('.close_map').click(function () {
            $('#map_directions').fadeOut(function () {
                directionsDisplay.setPanel(null);
                directionsDisplay.setMap(null);
                map.setZoom(map_config.map_zoom);
                map.setCenter(new google.maps.LatLng(map_config.hotel_info.latitude, map_config.hotel_info.longitude));
            });
            $('.itineraryPanelWrapper').remove();
        });

    }

    function savoy_dubai_handler_homepage(){
        if($('html').hasClass('group-hp') || $('html').hasClass('hotel-hp')){
            var __home_map_loaded = false;
            var position_map = $('#map_canvas').offset().top - $('#map_canvas').outerHeight();
            $(window).on("scroll", function() {
                var scrollPosition = window.pageYOffset;
                if(!__home_map_loaded && scrollPosition >= position_map){
                    __home_map_loaded = true;
                    if (($('#map_canvas').length > 0)) {
                        savoy_dubai_initializeMapGroup();
                    }
                }
            });
        } else {
            if (($('#map_canvas').length > 0)) {
                savoy_dubai_initializeMapGroup();
            }
        }
    }

    function savoy_dubai_whatsapp(){
        if($('.whatsapp-wrapper').length >0){
            $('.whatsapp-wrapper').on('mouseover', function(){
                $('.whatsapp-box').fadeIn(1000);
            });
            $('.whatsapp-wrapper').on('mouseleave', function(){
                $('.whatsapp-box').fadeOut(1000);
            });
        }
    }

    function savoy_dubai_social_media(){
        var social_media_carousel = $('.social-media-carousel');
        if(social_media_carousel.length > 0){
            social_media_carousel.on('init', function(event, slick){
                lazyLoadInstance.update();
            });

            social_media_carousel.not('.slick-initialized').slick({
                rows:0,
                slidesToShow: 4,
                arrows: false,
                dots:true,
                dotsClass: 'slick-dots-inverse',
                variableWidth: true,
                autoplay: true,
                pauseOnHover: false,
                rtl: hasRtl,
                prevArrow: '<a class="slick-arrow inverse prev" href="javascript:;"><span></span></a>',
                nextArrow: '<a class="slick-arrow inverse next" href="javascript:;"><span></span></a>',
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 1,
                            centerMode:true,
                        }
                    },
                ]
            });
        }
    }

    function savoy_dubai_bookingform(){
        $('.hotels-select').selectric({
            arrowButtonMarkup: '<b class="button"></b>',
            maxHeight: 'auto',
        });
    }

    function savoy_dubai_photogallery(){
        if($('html').hasClass('page-template-template-hotel-photogallery')){
            var photogallery_wrap = $('.photogallery-wrap');
            if(photogallery_wrap.length > 0){
                photogallery_wrap.isotope({
                    itemSelector: '.single-image',
                    percentPosition: true,
                    masonry: {
                        columnWidth: '.single-image'
                    }
                });

                $('.filter-item').on( 'click', function() {
                    var filterValue = $(this).data('category');
                    $(this).addClass('is-active').siblings().removeClass('is-active');
                    photogallery_wrap.isotope({ filter: filterValue });
                });

                $('.mobile_filter_container select').change(function () {
                    photogallery_wrap.isotope({filter: this.value});
                });
            }
        }
    }

    function savoy_dubai_social_login(){
        $(document).on('click', '#login a', function(){
           $('.dropdown').fadeIn('fast');

        });
        $(document).on('click', '.dropdown .close-btn', function(){
            $('.dropdown').fadeOut('fast');
        });

        //Close and open guest accoun widget
        if(!is_mobile){
            $('.close-offer-widget').on('click', function () {
                if(!$('.offer-login-wrapper').hasClass('opened')){
                    $('.offer-login-wrapper').addClass('opened');
                } else {
                    $('.offer-login-wrapper').removeClass('opened');
                }
            });
        }
    }

    function savoy_dubai_highlight_posts(){
        if($('html').hasClass('blog')){
            var highlight_posts = $('.highlight-posts-carousel');
            if(highlight_posts.length > 0){
                highlight_posts.not('.slick-initialized').slick({
                    rows: 0,
                    autoplay: true,
                    pauseOnHover: false,
                    autoplaySpeed: 4000,
                    speed: 2500,
                    waitForAnimate: false,
                    rtl: hasRtl,
                    prevArrow: '<a class="slick-arrow big rounded inverse opacity prev" href="javascript:;"><span></span></a>',
                    nextArrow: '<a class="slick-arrow big rounded inverse opacity next" href="javascript:;"><span></span></a>',
                });
            }
        }
    }

    function savoy_dubai_siblings_rooms() {
        var siblings_rooms = $('.siblings-carousel');
        var siblings_children = siblings_rooms.children();
        var start_carousel = false;
        if(is_mobile && siblings_children.length > 0){
            start_carousel = true;
        } else {
            if(siblings_children.length > 2){
                start_carousel = true
            }
        }


        if(siblings_rooms.length > 0 && start_carousel){
            siblings_rooms.on('init', function(event, slick){
                lazyLoadInstance.update();
            });
            siblings_rooms.slick({
                rows: 0,
                slidesToShow: 2,
                slidesToScroll: 1,
                rtl: hasRtl,
                dots: false,
                prevArrow: '<a class="slick-arrow prev" href="javascript:;"><span></span></a>',
                nextArrow: '<a class="slick-arrow next" href="javascript:;"><span></span></a>',
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 2,
                            prevArrow: '<a class="slick-arrow rounded prev" href="javascript:;"><span></span></a>',
                            nextArrow: '<a class="slick-arrow rounded next" href="javascript:;"><span></span></a>',
                        }
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 1,
                            prevArrow: '<a class="slick-arrow rounded prev" href="javascript:;"><span></span></a>',
                            nextArrow: '<a class="slick-arrow rounded next" href="javascript:;"><span></span></a>',
                        }
                    },
                ]
            })
        }
    }

    function savoy_dubai_siblings_blog(){
        var start_carousel = false;
        var siblings_blog_wrapper = $('.siblings-blog-carousel');
        var children = siblings_blog_wrapper.children();
        var maxHeight = 0;

        //Equal height
        $(".siblings-blog-carousel .item .content").each(function(){
            if ($(this).height() > maxHeight) {
                maxHeight = $(this).height();
            }
        });

        $(".siblings-blog-carousel .item .content").height(maxHeight);


        if(siblings_blog_wrapper.length > 0){
            if(children.length > 3 || (is_mobile && children > 0)){
                start_carousel = true;
            }

            if(start_carousel){
                siblings_blog_wrapper.on('init', function(event, slick){
                    lazyLoadInstance.update();
                });
                siblings_blog_wrapper.not('.slick-initialized').slick({
                    rows: 0,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    rtl: hasRtl,
                    autoplay: true,
                    pauseOnHover: false,
                    autoplaySpeed: 4000,
                    speed: 2500,
                    waitForAnimate: false,
                    dots: true,
                    arrows: false,
                    dotsClass: 'slick-dots-inverse',
                    responsive: [
                        {
                            breakpoint: 992,
                            settings: {
                                slidesToShow: 2,
                                dots: false,
                            }
                        },
                        {
                            breakpoint: 767,
                            settings: {
                                slidesToShow: 1,
                                dots: false,
                            }
                        },
                    ]
                });
            }
        }
    }

    function savoy_dubai_form(){
        if($(".gform_wrapper").length > 0){
            $('.datepicker').each(function(){
                $(this).datepicker({
                    minDate: "d",
                    defaultDate: "d",
                    dateFormat: "d/m/yy",
                    changeMonth: false,
                    changeYear: false,
                });
            });
        }
    }

    //faq
    function faq_collapsibleContent() {
        var button = $('.all-faqs-section .collapsibleContent_btn'),
            mClass = 'open',
            mClass2 = 'content_is_visible';

        button.click(function () {
            //fix height when resize the page
            window.addEventListener('resize', function (event) {
                if ($('.single_faq').hasClass('content_is_visible')) {
                    $('.description_wrap ').css({
                        height: $('.description.internal_wrap').outerHeight(true)
                    });
                } else {
                    $('.description_wrap').css({
                        height: 0
                    });
                }
            });

            var btn = $(this),
                parent = btn.parent(),
                wrap = parent.find('.collapsibleContent');
            if (!btn.hasClass(mClass)) {
                var height = wrap.find('.internal_wrap').outerHeight(true);
                wrap.css({
                    'height': height,
                });
                btn.addClass(mClass);
                $(parent).addClass(mClass2);

            } else {
                wrap.css({
                    'height': 0,
                });
                btn.removeClass(mClass);
                $(parent).removeClass(mClass2);
            }
        });
    }

    // Body
    window.bodySelectCheck = document.body;
    // Head
    window.wDoc = window.document;
    window.head_tag = wDoc.getElementsByTagName("head")[0];

    window.loadCSS_fast = function (link_style, id_style = 'loadcss_fast', before) {
        // Remember to send the ID of the CSS to make the validation
        // Check with ID
        var check_exist_css = document.getElementById(id_style);
        if (check_exist_css == null) {
            var link = document.createElement("link");

            link.type = "text/css";
            link.rel = "stylesheet";
            link.href = link_style;
            link.id = id_style;

            // If before is send insert before the ID
            // If before is empty insert before the head close
            var ref;
            if (before) {
                ref = document.getElementById(before);
            } else {
                var refs = (head_tag || wDoc.body).childNodes;
                ref = refs[refs.length - 1];
            }
            ref.parentNode.insertBefore(link, (before ? ref : ref.nextSibling));
            console.log('Asset loaded ' + id_style);

        }
    }

    function savoy_dubai_icons() {
        loadCSS_fast('//staticaws.fbwebprogram.com/FBServicesWebfonts/css/services_webfont.css', 'icons_css');

        bodySelectCheck.classList.add('icons-loaded') // Avoid showing while is loading
    }



    //Load Window
    $(window).on('load', function () {
        savoy_dubai_refresh_size_queries();
        savoy_dubai_slideshow();
        //savoy_dubai_slideshow_height();
        savoy_dubai_photogallery();
        $('.menu-sidebar').mCustomScrollbar({
            axis:"y",
            theme:"dark"
        });
    });
    //Resize Window
    $(window).resize(function () {
        savoy_dubai_refresh_size_queries();
        //savoy_dubai_slideshow_height();
    });
    //Dom ready
    $(document).ready(function () {
        window.lazyLoadInstance = new LazyLoad({
            elements_selector: ".lazy",
            threshold: 100
        });

        savoy_dubai_refresh_size_queries();
        //savoy_dubai_seo_position();
        savoy_dubai_icons();
        savoy_dubai_header_position();
        savoy_dubai_social_login();
        savoy_dubai_hotels_select();
        savoy_dubai_languages_select();
        savoy_dubai_sidebar_menu();
        savoy_dubai_sidebarmenu_submenu();
        //savoy_dubai_slideshow_height();
        savoydubai_collapsibleContent();
        savoy_dubai_default_fancybox();
        savoy_dubai_minigallery();
        savoy_dubai_reviews();
        savoy_dubai_web_sdk_offers();
        savoy_dubai_whatsapp();
        savoy_dubai_social_media();
        savoy_dubai_bookingform();
        savoy_dubai_preview_rooms_carousel();
        savoy_dubai_align_text();
        savoy_dubai_rooms_services();
        savoy_dubai_siblings_rooms();
        savoy_dubai_form();
        if($('.preview-offers').length > 0){
            savoy_dubai_preview_offers_carousel();
            savoy_dubai_equal_height();
        }

        if($('.preview-offers').length > 0 || $('html').hasClass('tpl-hotel-manual-offers')){
            savoy_dubai_equal_height();
        }

        window.onscroll = function () {
            savoy_dubai_header_position();
        }

        savoy_dubai_handler_homepage();
        savoy_dubai_highlight_posts();
        savoy_dubai_siblings_blog();
        faq_collapsibleContent();
        if($('html').hasClass('hotel-hp') || $('html').hasClass('page-template-template-hotel-location')){
            /*Manage itinerary*/
            window.travel_mode = 'DRIVING';
            $('.travel-mode a').on('click',function() {
                travel_mode = $(this).data('travel');
                $(this).addClass('active').siblings().removeClass('active');
            });
            $('#itinerarySubmit').on('click', function () {
                savoydubai_calcRoute('map_canvas');
            });
        }

        //This is an hack for from chrome mobile because the browser not render some D-EDGE custom icon
        if(is_mobile){
            setTimeout(function(){
                $('.preview-items-content .single-item .icon').each(function(){
                    $(this).css({'font-size':60});
                });
            }, 3000);
        }

    });
})(jQuery);